import Goldencity from './Goldencity';
import FuchsUndWald from './FuchsUndWald';
import FastForwardScience from './FastForwardScience';
import VolligOhne from './VolligOhne';
import TransformationDesAlternativen from './TransformationDesAlternativen';
import TimoBecker from './TimoBecker';
import StrassenSchilder from './StrassenSchilder';
import Tiefseemonster from './Tiefseemonster';
import Lieblingsplatte from './Lieblingsplatte';
import Vostel from './Vostel';
import Schnappenburg from './Schnappenburg';
import PocoLit from './PocoLit';
import NewBooksInGerman from './NewBooksInGerman';

export default {
    '/projekte/goldencity/': Goldencity,
    '/projekte/fastforwardscience/': FastForwardScience,
    '/projekte/fuchsundwald/': FuchsUndWald,
    '/vo/': VolligOhne,
    '/projekte/transformationdesalternativen/': TransformationDesAlternativen,
    '/projekte/timobecker/': TimoBecker,
    '/projekte/93schilder/': StrassenSchilder,
    '/projekte/jona/': Tiefseemonster,
    '/projekte/lieblingsplatte/': Lieblingsplatte,
    '/projekte/vostel/': Vostel,
    '/projekte/schnappenburg/': Schnappenburg,
    '/projekte/pocolit/': PocoLit,
    '/projekte/newbooksingerman/': NewBooksInGerman,
};
